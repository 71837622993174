import axios from "../axios";

class OrderItemsService {
  // getAll(
  //   pageNo,
  //   {
  //     pageSize = 10,
  //     trackingNumber,
  //     internationalTrackingNumber,
  //     orderiiNumbers,
  //     phoneNumber,
  //     itemName,
  //     region,
  //     vendorIds,
  //     itemStatus,
  //     invoiceNumber,
  //     shippingNumber,
  //     isVip,
  //     sortByCreatedAtAsc,
  //     isWrongTrackingNumber,
  //     hasPurchaseError,
  //     isVendorExcluded,
  //     isDamaged,
  //     isDangerousGoods,
  //     shippingOptionName,
  //   }
  // ) {
  //   var conditions = "";
  //   if (itemName) conditions += `&itemName=${itemName}`;
  //   if (region) conditions += `&region=${region}`;
  //   // if (itemStatus) conditions += `&itemStatus=${itemStatus}`;
  //   if (trackingNumber) conditions += `&trackingNumber=${trackingNumber}`;
  //   if (internationalTrackingNumber)
  //     conditions += `&internationalTrackingNumber=${internationalTrackingNumber}`;
  //   if (invoiceNumber) conditions += `&invoiceNumber=${invoiceNumber}`;
  //   if (shippingNumber) conditions += `&shippingNumber=${shippingNumber}`;
  //   if (orderiiNumbers) conditions += `&orderiiNumbers=${orderiiNumbers}`;
  //   if (phoneNumber) conditions += `&phoneNumber=${phoneNumber}`;
  //   if ((isVip !== null) & (isVip !== undefined))
  //     conditions += `&isVip=${isVip}`;
  //   conditions += `&sortByCreatedAtAsc=${sortByCreatedAtAsc}`;
  //   if (isWrongTrackingNumber)
  //     conditions += `&isWrongInternationalTrackingNumber=${isWrongTrackingNumber}`;
  //   if (hasPurchaseError) conditions += `&hasPurchaseError=${hasPurchaseError}`;
  //   if (isDangerousGoods) conditions += `&isDangerousGoods=${isDangerousGoods}`;
  //   if (isDamaged) conditions += `&isDamaged=${isDamaged}`;
  //   if (isVendorExcluded) conditions += `&isVendorExcluded=${isVendorExcluded}`;
  //   if (vendorIds)
  //     vendorIds.forEach((element) => {
  //       conditions += `&vendorIds=${element}`;
  //     });
  //   if (itemStatus)
  //     itemStatus.forEach((element) => {
  //       conditions += `&itemStatus=${element}`;
  //     });
  //   if (
  //     shippingOptionName != null &&
  //     shippingOptionName !== "" &&
  //     shippingOptionName !== undefined
  //   )
  //     conditions += `&shippingOptionName=${shippingOptionName}`;
  //   return axios.get(
  //     `/OrderItem/all/${pageNo}?pageSize=${pageSize}${conditions}`
  //   );
  // }

  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          data[key] !== "" &&
          (data[key] !== null) & (data[key] !== undefined)
        ) {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/OrderItem/all/${pageNo}?${params}`);
  }

  getAllFilteredOnly(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/orderItem/getallfilteredonly/${pageNo}?${params}`);
  }

  updateStatus(data) {
    return axios.put(`/OrderItem/status`, data);
  }

  updateItemInternalStatus(data) {
    return axios.put(`/OrderItem/internalStatus`, data);
  }

  updateTrackingNumber(data) {
    return axios.put(`/OrderItem/trackingnumber`, data);
  }

  updateInternationalTrackingNumber(data) {
    return axios.put(`/OrderItem/internationaltrackingnumber`, data);
  }

  updateInvoiceNumber(data) {
    return axios.put(`/OrderItem/invoicenumber`, data);
  }

  deliveryDateUpdate(data) {
    return axios.put(`/OrderItem/deliverydate`, data);
  }

  updateNote(data) {
    return axios.put(`/OrderItem/note`, data);
  }

  changeNewStatus(data) {
    return axios.put(`/OrderItem/newStatus`, data);
  }

  generateShippingNumber(data) {
    return axios.put(`/OrderItem/generateShippingNumber`, data);
  }

  getHistory(id) {
    return axios.get(`/OrderItem/statushistories/${id}`);
  }

  purchasePrice(id, data) {
    return axios.put(`/OrderItem/purchasingprice/${id}`, data);
  }

  trackingInfo(data) {
    return axios.put(`/OrderItem/trackinginfo`, data);
  }

  wrongTackingNumber(data) {
    return axios.put(`/OrderItem/wrongtrackingnumber`, data);
  }

  hasPurchaseError(id) {
    return axios.put(`/OrderItem/togglepurchaseerror/${id}`);
  }

  updateShipmentItemsStatus(data) {
    return axios.put(`/orderitem/modifystatustoshippedtoiraq`, data);
  }

  toggleIsDamaged(id) {
    return axios.put(`/OrderItem/toggleisdamaged/${id}`);
  }

  toggleIsDangerousGoods(id) {
    return axios.put(`/OrderItem/toggleisdangerousgoods/${id}`);
  }

  updateLocalShippingCompany(id, data) {
    return axios.put(`/OrderItem/localshippingcompany/${id}`, data);
  }
}

export default new OrderItemsService();

import * as React from "react";
import { Box } from "@mui/system";
import {
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Chip,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Icon } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { CustomDialog } from "src/components/CustomDialog";
import OrderStatusMenu from "../../menu/OrderStatus";
import RolePermission from "src/components/RolePermission";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel, MdOutlineStarRate } from "react-icons/md";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import TimelineDialog from "src/pages/gifts/orders/timeline/OrderTimeline";
import ReactStars from "react-rating-stars-component";
import {
  cancelOrder,
  getOrderById,
  openCODdialog,
  refundOrder,
  updateEmployeeNotes,
} from "src/actions/gifts/orders";
import { BsCashCoin } from "react-icons/bs";
import CODForm from "src/pages/gifts/orders/orderDetails/form/COD";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ReceiptIcon from "@mui/icons-material/Receipt";
import GenerateInvoice from "src/pages/gifts/orders/orderDetails/generate/generateInvoice";
import GenerateLabel from "src/pages/gifts/orders/orderDetails/generate/generateLabel";
import ReactCountryFlag from "react-country-flag";
import { country_codes } from "src/utils/common";
import { Controller, useForm } from "react-hook-form";
import { BiNotepad } from "react-icons/bi";
import { useEffect } from "react";
import { openDialog } from "src/actions/orderAddtionalFees";
import UserTierMenu from "src/pages/cross/Users/userTierMenu";
import { openNoteHistoryDialog } from "src/actions/orders";
import NoteHistoryDialog from "src/pages/cross/orders/noteHistory/noteHistory";

export default function Details(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const order = useSelector((state) => state.giftOrder.form);
  const CODDialog = useSelector((state) => state.giftOrder.openCODDialog);
  const orderNoteHistoryDialog = useSelector(
    (state) => state.orders.orderHistoryDialog
  );
  const loading = useSelector((state) => state.giftOrder.reload);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const searchBody = useSelector((state) => state.giftItems.searchBody);

  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openLabelDialog, setOpenLabelDialog] = useState(false);

  const [openRatingDialog, setOpenRatingDialog] = useState(false);
  const [ratingData, setRatingData] = useState({});

  function handleDates(date) {
    const newDate = moment(date).startOf("day");
    const now = moment().startOf("day");
    if (newDate.isSame(now, "day")) {
      return <Chip color="success" label={"Today"} size="small" />;
    } else {
      return (
        <Typography variant="body">
          {moment(date).format("YYYY-MM-DD / hh:mm a")}
        </Typography>
      );
    }
  }

  const [showPanZoom, setShowPanZoom] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      notes: order?.notes ?? "",
    },
  });

  useEffect(
    () => setValue("notes", order?.employeeNotes ? order?.employeeNotes : ""),
    [order, setValue]
  );

  const onSubmit = (data) => {
    dispatch(updateEmployeeNotes(order.id, data.notes));
  };

  return (
    <Box>
      {orderNoteHistoryDialog && <NoteHistoryDialog />}
      {CODDialog && (
        <CODForm
          historyData={order}
          loading={loading}
          pageNo={pageNo}
          searchBody={searchBody}
          dialog={CODDialog}
        />
      )}
      <CustomDialog
        isOpen={openInvoiceDialog}
        handleClose={() => setOpenInvoiceDialog(false)}
        title={"INVOICE"}
        maxWidth="md"
      >
        <GenerateInvoice data={order} />
      </CustomDialog>

      <CustomDialog
        isOpen={openLabelDialog}
        handleClose={() => setOpenLabelDialog(false)}
        title={"Label"}
        maxWidth="md"
      >
        <GenerateLabel data={order} />
      </CustomDialog>
      <CustomDialog
        isOpen={openImageDialog}
        handleClose={() => handleDialogClose()}
        title="Images"
        maxWidth="lg"
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width: "50%",
              height: "auto",
            }}
          >
            <ReactPanZoom image={showPanZoom} alt="Image alt text" />
          </div>
        </div>
      </CustomDialog>
      <CustomDialog
        isOpen={openRatingDialog}
        handleClose={() => setOpenRatingDialog(false)}
        title="Order Rating"
        maxWidth="md"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h3">Average:</Typography>
          <ReactStars
            edit={false}
            value={ratingData?.ratingAverage}
            size={40}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {ratingData?.rates?.map((data, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="h4">
                {data?.ratingQuestion?.questionEn}
              </Typography>
              <ReactStars edit={false} value={data.rate} size={32} />
              {index !== ratingData?.rates?.length - 1 && <Divider />}
            </Grid>
          ))}
        </Grid>
      </CustomDialog>
      <TimelineDialog
        open={openTimelineDialog}
        handleClose={() => setOpenTimelineDialog(false)}
        orderId={order.id}
      />
      <CustomDialog
        isOpen={openConfirmDialog}
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
        isActiveDelete
        isRequierdActions
        title="Delete confirmation"
        maxWidth="sm"
        isConfirm
        isConfirmation
        confirmTitle={isCancel ? "Cancel" : "refund"}
        onCancel={() =>
          isCancel
            ? dispatch(cancelOrder(order?.id)).then(() =>
                setOpenConfirmDialog(false)
              )
            : dispatch(refundOrder(order?.id)).then(() =>
                setOpenConfirmDialog(false)
              )
        }
        selectedObject={`${order?.number}`}
      ></CustomDialog>
      {order.giftItems?.length !== 0 ? (
        <Card sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack
                sx={{ alignItems: "center", py: 0.5 }}
                direction={"row"}
                spacing={1}
              >
                <OrderStatusMenu
                  orderId={order.id}
                  status={order.status}
                  statusNumber={order.statusNumber}
                  onSuccess={() => props.refresh()}
                  onCancel={() => {
                    setIsCancel(true);
                    setOpenConfirmDialog(true);
                  }}
                />
                {order?.statusNumber === 6 &&
                  (order?.isMoneyRefunded ? (
                    <Chip color="success" label={"Refunded"} size="small" />
                  ) : (
                    <Chip color="error" label={"Not Refunded"} size="small" />
                  ))}
                {(order?.statusNumber === 6) & !order?.isMoneyRefunded ? (
                  <RolePermission permission="47-14">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setIsCancel(false);
                        setOpenConfirmDialog(true);
                      }}
                    >
                      Refund
                    </Button>
                  </RolePermission>
                ) : null}
              </Stack>
              <Typography variant="h4" sx={{ py: 1 }}>
                {order.number ?? ""}
              </Typography>
              <Typography color="primary" variant="h4" sx={{ py: 1 }}>
                Payment Type: {order.paymentType ?? ""}
              </Typography>
              {order.paymentTypeId === 0 ? null : <Divider />}
              {order.paymentTypeId === 2 ? (
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    color="secondary"
                    sx={{
                      py: 0.5,
                      width: "75%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    Wallet Paid Amount (IQD):
                  </Typography>
                  <Typography
                    color="secondary"
                    sx={{
                      py: 0.5,
                      width: "25%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    {order.walletPaidAmount?.toLocaleString() ?? 0}
                  </Typography>
                </Stack>
              ) : null}
              {order.paymentTypeId === 0 ? null : (
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    color="secondary"
                    sx={{
                      py: 0.5,
                      width: "75%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    Cash On Delivery Paid Amount (IQD):
                  </Typography>
                  <Typography
                    color="secondary"
                    sx={{
                      py: 0.5,
                      width: "25%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    {order.codPaidAmount?.toLocaleString() ?? 0}
                  </Typography>
                </Stack>
              )}
              {order.paymentTypeId === 0 ? null : (
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    color="secondary"
                    sx={{
                      py: 0.5,
                      width: "75%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    Cash On Delivery Remaining Amount (IQD):
                  </Typography>
                  <Typography
                    color="secondary"
                    sx={{
                      py: 0.5,
                      width: "25%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    {order.remainingCODAmount?.toLocaleString() ?? 0}
                  </Typography>
                </Stack>
              )}
              {order.paymentTypeId === 0 ? null : <Divider />}
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    py: 0.5,
                    width: "75%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  Total Amount Before Discount (IQD):
                </Typography>
                <Typography
                  sx={{
                    py: 0.5,
                    width: "25%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  {order.totalAmountBeforeDiscount?.toLocaleString() ?? 0}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  color="error"
                  sx={{
                    py: 0.5,
                    width: "75%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  Total Discount (IQD):
                </Typography>

                <Typography
                  color="error"
                  sx={{
                    py: 0.5,
                    width: "25%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  {order.totalDiscount?.toLocaleString() ?? 0}
                </Typography>
              </Stack>
              {order.promoCode ? (
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    sx={{
                      py: 0.5,
                      width: "75%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    Promo Code Name:
                  </Typography>

                  <Typography
                    sx={{
                      py: 0.5,
                      width: "25%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    {order.promoCode?.name ?? ""}
                  </Typography>
                </Stack>
              ) : null}
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    py: 0.5,
                    width: "75%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  Delivery Price (IQD):
                </Typography>
                <Typography
                  sx={{
                    py: 0.5,
                    width: "25%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  {order.deliveryPrice?.toLocaleString() ?? 0}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    py: 0.5,
                    width: "75%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  Service Fee (IQD):
                </Typography>
                <Typography
                  sx={{
                    py: 0.5,
                    width: "25%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  {order.serviceTaxes?.toLocaleString() ?? 0}
                </Typography>
              </Stack>
              {order.aqsatiFee ? (
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    sx={{
                      py: 0.5,
                      width: "75%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    Aqsati Fee:
                  </Typography>

                  <Typography
                    sx={{
                      py: 0.5,
                      width: "25%",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    {order.aqsatiFee.toLocaleString() ?? ""}
                  </Typography>
                </Stack>
              ) : null}
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    color: "green",
                    py: 0.5,
                    width: "75%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  Total Amount (IQD):
                </Typography>
                <Typography
                  sx={{
                    color: "green",
                    py: 0.5,
                    width: "25%",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  {order.totalAmountAfterDiscount?.toLocaleString() ?? 0}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={0.5}>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item xs={3.5}>
              <Stack direction={"row"} spacing={1} sx={{ py: 0.5 }}>
                <Typography variant="h5">
                  Ask Recipient For Address:{" "}
                </Typography>
                {order.askReceiver ? (
                  <FaCheckCircle color="green" size={25} />
                ) : (
                  <MdCancel color="#d32f2f" size={25} />
                )}
              </Stack>
              <Stack direction={"row"} spacing={1} sx={{ py: 0.5 }}>
                <Typography variant="h5">Is Fast Delivery: </Typography>
                <Stack direction={"row"} spacing={1}>
                  {order.isFastDelivery ? (
                    <FaCheckCircle color="green" size={25} />
                  ) : (
                    <MdCancel color="#d32f2f" size={25} />
                  )}
                </Stack>
              </Stack>
              <Stack direction={"row"} spacing={1} sx={{ py: 0.5 }}>
                <Typography variant="h5">Show Sender Name: </Typography>
                <Stack direction={"row"} spacing={1}>
                  {order.showSenderName ? (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <FaCheckCircle color="green" size={25} />
                      <Typography color="success" variant="h5">
                        {order.senderName}
                      </Typography>
                    </Stack>
                  ) : (
                    <MdCancel color="#d32f2f" size={25} />
                  )}
                </Stack>
              </Stack>
              <Typography variant="h5" sx={{ py: 0.5 }}>
                Exchange Rate:
                {order.exchangeRatePrice?.toLocaleString() ?? 0} IQD
              </Typography>
              {order.notes ? (
                <Typography variant="h5" sx={{ py: 0.5 }}>
                  Notes: {order.notes}
                </Typography>
              ) : null}

              {order.giftWrappingType || order.giftWishCard || order.message ? (
                <Box>
                  <Divider>
                    <Chip label="Wrapping" sx={{ my: 2 }} />
                  </Divider>
                  {order.message ? (
                    <Typography variant="h5" sx={{ py: 0.5 }}>
                      Message:
                      <br />
                      {order.message.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          <strong> {line}</strong>
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  ) : null}
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column">
                      {order.giftWishCard ? (
                        <>
                          <Typography variant="h5" sx={{ py: 0.5 }}>
                            Wish Card:
                          </Typography>
                          <Box
                            onClick={() =>
                              handleOpenPanZoom(
                                order.giftWishCard?.attachment?.url
                              )
                            }
                            component="img"
                            src={order.giftWishCard?.attachment?.url}
                            width={150}
                          />
                        </>
                      ) : null}
                    </Stack>
                    <Stack direction="column">
                      {order.giftWrappingType ? (
                        <>
                          <Typography variant="h5" sx={{ py: 0.5 }}>
                            Wrapping Type:
                          </Typography>
                          <Box
                            onClick={() =>
                              handleOpenPanZoom(
                                order.giftWrappingType?.attachment?.url
                              )
                            }
                            component="img"
                            src={order.giftWrappingType?.attachment?.url}
                            width={150}
                          />
                        </>
                      ) : null}
                    </Stack>
                  </Stack>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={0.5}>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item xs={3.5}>
              <Divider>
                <Chip label="Created By" />
              </Divider>
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                sx={{ py: 0.5 }}
              >
                <Icon>account_circle</Icon>
                <Typography variant="h5">
                  : {order.user?.name}{" "}
                  <UserTierMenu
                    userId={order?.user?.id}
                    tier={order?.user?.customerTier}
                    onSuccess={() => dispatch(getOrderById(order?.id))}
                  />
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                sx={{ py: 0.5 }}
              >
                <Icon>call</Icon>
                <Stack direction="row" spacing={1} alignItems="center">
                  <ReactCountryFlag
                    style={{
                      width: "40px",
                      height: "30px",
                      border: "solid 1px #dedede",
                    }}
                    svg
                    countryCode={
                      country_codes.filter(
                        (code) => code.dial_code === order.user?.countryCode
                      )[0]?.code
                    }
                  />
                  <Typography variant="body">
                    {order.user?.phoneNumber}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Created at date">
                  <Icon>today</Icon>
                </Tooltip>
                {handleDates(order.createdAt)}
              </Stack>
              <Divider sx={{ mb: 0.5, mt: 3 }}>
                <Chip label="Recipient Information" />
              </Divider>
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                sx={{ py: 0.5 }}
              >
                <Icon>account_circle</Icon>
                <Typography variant="h5">: {order.receiverName}</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                sx={{ py: 0.5 }}
              >
                <Icon>call</Icon>
                <Typography variant="body1">: {order.phoneNumber}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Delivery date">
                  <Icon>today</Icon>
                </Tooltip>
                {handleDates(order.deliveryDate)}
              </Stack>
              <Stack
                direction="row"
                spacing={0}
                alignItems="start"
                sx={{ py: 0.5 }}
              >
                <Icon>pin_drop</Icon>
                <Typography variant="h5">
                  : {order.receiverAddress?.city?.province?.nameUS},{" "}
                  {order.receiverAddress?.city?.nameUS},{" "}
                  {order.receiverAddress?.neighborhood},{" "}
                  {order.receiverAddress?.nearestLocation}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box sx={{ margin: 1 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Notes"
                        fullWidth
                        multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BiNotepad size={25} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <Stack direction="row" spacing={1}>
                              <RolePermission permission="47-18">
                                <InputAdornment
                                  position="end"
                                  sx={{ marginY: 2 }}
                                >
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </InputAdornment>
                              </RolePermission>
                              <RolePermission permission="22-16">
                                <Button
                                  variant="contained"
                                  color="warning"
                                  fullWidth
                                  onClick={() => {
                                    dispatch(
                                      openNoteHistoryDialog({
                                        id: order.id,
                                        type: "giftOrder",
                                      })
                                    );
                                  }}
                                >
                                  History
                                </Button>
                              </RolePermission>
                            </Stack>
                          ),
                        }}
                      />
                    )}
                  />
                </form>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} display="flex" alignItems="center">
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ justifyContent: "center", width: "75%" }}
              >
                <RolePermission permission="58-01">
                  <Tooltip
                    title="Order Additional Fees"
                    onClick={(e) => {
                      dispatch(openDialog("gift"));
                    }}
                  >
                    <IconButton>
                      <Icon color="success">attach_money</Icon>
                    </IconButton>
                  </Tooltip>
                </RolePermission>
                {order?.orderRating?.length > 0 ? (
                  <Tooltip
                    title="Order Rating"
                    onClick={() => {
                      setOpenRatingDialog(true);
                      setRatingData(order?.orderRating[0]);
                    }}
                  >
                    <IconButton>
                      <MdOutlineStarRate />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {_UserInfo.roles.includes("PrintLabelAndInvoice") ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ justifyContent: "center" }}
                  >
                    <Tooltip
                      title="Print Invoice"
                      onClick={() => setOpenInvoiceDialog(true)}
                    >
                      <IconButton>
                        <LocalPrintshopIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Print Label"
                      onClick={() => setOpenLabelDialog(true)}
                    >
                      <IconButton>
                        <ReceiptIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : null}
                <RolePermission permission="47-09">
                  <Tooltip
                    title="Timeline"
                    onClick={() => setOpenTimelineDialog(true)}
                  >
                    <IconButton>
                      <Icon>show_chart</Icon>
                    </IconButton>
                  </Tooltip>
                </RolePermission>
                <RolePermission permission="34-12">
                  <Tooltip
                    title="Go to wallet"
                    onClick={() => {
                      navigate(
                        `/app/wallet/${order.user ? order.user.id : null}`
                      );
                    }}
                  >
                    <IconButton>
                      <Icon>wallet</Icon>
                    </IconButton>
                  </Tooltip>
                </RolePermission>
                {order.remainingCODAmount > 0 &&
                order.statusNumber === 5 &&
                order.paymentTypeId !== 0 ? (
                  <RolePermission permission="47-16">
                    <Tooltip
                      title="Cash on delivery amount"
                      onClick={() => {
                        dispatch(openCODdialog());
                      }}
                    >
                      <IconButton>
                        <BsCashCoin size={20} />
                      </IconButton>
                    </Tooltip>
                  </RolePermission>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ) : null}
    </Box>
  );
}

import { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Tooltip,
  FormControlLabel,
  Switch,
  Autocomplete,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider,
  List,
  Card,
  CardHeader,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  UpdateEPayments,
  createEPayments,
  getEPaymentsById,
  openEPaymentsDialog,
  resetForm,
} from "src/actions/EPayments";
import { Controller, useForm } from "react-hook-form";
import { E_PAYMENT_TYPES } from "src/utils/common";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getAllAddresses } from "src/actions/addresses";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

const EPaymentsForm = () => {
  const dispatch = useDispatch();

  const _EPayments = useSelector((state) => state.epayments);
  const _Addresses = useSelector((state) => state.addresses);

  const [loading, setLoading] = useState(false);

  const isAdd = _EPayments.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: _EPayments.form,
  });

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  useEffect(() => {
    reset({ ..._EPayments.form });
  }, [_EPayments.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line no-use-before-define
  }, [dispatch]);

  const onSubmit = (data) => {
    if (rightChecked.length > 0 || leftChecked.length > 0) {
      dispatch(
        showErrorSnackbar(
          "You have selected some provinces and didn't move them to the right side, please move them to the right side or remove them from the left side"
        )
      );
    } else {
      function getValueByKey(key) {
        switch (key) {
          case "All":
            return 0;
          case "Global":
            return 1;
          case "Vertical":
            return 2;
          default:
            return "Unknown";
        }
      }
      setLoading(true);
      if (isAdd) {
        dispatch(
          createEPayments(
            {
              name: data.name,
              logo: data.logo,
              type: getValueByKey(data.type),
              isActive: data.isActive,
              isPersonal: data.isPersonal,
              isInternational: data.isInternational,
              discountPercentage: parseFloat(data.discountPercentage),
              provinces: right,
            },
            _EPayments.type
          )
        ).then((x) => {
          setLoading(false);
          dispatch(
            getEPaymentsById({
              id: null,
              name: "",
              logo: "",
              discountPercentage: "",
              isActive: false,
              isPersonal: false,
              isInternational: false,
              provinces: [],
            })
          );
        });
      } else {
        dispatch(
          UpdateEPayments(
            {
              id: data.id,
              name: data.name,
              logo: data.logo,
              type: getValueByKey(data.type),
              isActive: data.isActive,
              isPersonal: data.isPersonal,
              isInternational: data.isInternational,
              discountPercentage: parseFloat(data.discountPercentage),
              provinces: right,
            },
            _EPayments.type
          )
        ).then((x) => {
          setLoading(false);
          dispatch(
            getEPaymentsById({
              id: null,
              name: "",
              logo: "",
              discountPercentage: "",
              isActive: false,
              isPersonal: false,
              isInternational: false,
              provinces: [],
            })
          );
        });
      }
    }
  };

  const handleDialog = () => {
    dispatch(openEPaymentsDialog(null));
  };

  // ------------- Province  ---------------- //

  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  useEffect(() => {
    const rightProvinces = _EPayments.form?.provinces;
    setRight(rightProvinces?.map((province) => province.id));
    setRightData(rightProvinces);
  }, [_EPayments.form?.provinces]);

  useEffect(() => {
    const leftProvinces = _Addresses?.filter(
      (province) => !right.includes(province.id)
    );
    setLeft(leftProvinces?.map((province) => province.id));
    setLeftData(leftProvinces);
  }, [_Addresses, right]);

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    const newLeft = not(left, leftChecked);
    setRight(newRight);
    setLeft(newLeft);
    setChecked(not(checked, leftChecked));
    setRightData(
      newRight.map((id) => _Addresses.find((province) => province.id === id))
    );
    setLeftData(
      newLeft.map((id) => _Addresses.find((province) => province.id === id))
    );
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    const newRight = not(right, rightChecked);
    setLeft(newLeft);
    setRight(newRight);
    setChecked(not(checked, rightChecked));
    setLeftData(
      newLeft.map((id) => _Addresses.find((province) => province.id === id))
    );
    setRightData(
      newRight.map((id) => _Addresses.find((province) => province.id === id))
    );
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, width: "100%" }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "920px",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {leftData?.map((province) => {
          const labelId = `transfer-list-all-item-${province.id}-label`;

          return (
            <ListItem
              key={province.id}
              role="listitem"
              onClick={handleToggle(province.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(province.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${province.nameUS} - ${province.nameAR}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  const selectedCustomList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, width: "100%" }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            disabled={items.length === 0}
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${items.length} Cities`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "920px",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {rightData?.map((province, index) => {
          const labelId = `transfer-list-all-item-${province.id}-label`;

          return (
            <ListItem
              key={province.id}
              role="listitem"
              onClick={handleToggle(province.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(province.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${province.nameUS} - ${province.nameAR}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <CustomDialog
      title={isAdd ? "ADD E-PAYMENTS" : "UPDATE E-PAYMENTS"}
      isOpen={_EPayments.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
        dispatch(
          getEPaymentsById({
            id: null,
            name: "",
            logo: "",
            isActive: false,
            isPersonal: false,
            provinces: [],
          })
        );
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disabled={loading}
                  options={E_PAYMENT_TYPES || []}
                  getOptionLabel={(option) => option.value}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField required {...params} label="Type" />
                  )}
                  onChange={(event, value) => {
                    setValue("type", value ? value.value : null, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    E_PAYMENT_TYPES?.filter(
                      (x) => x.value === field.value
                    )[0] ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name"
                  fullWidth
                  {...field}
                  value={field.value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="logo"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Logo"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="discountPercentage"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Discount Price Percent"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          {isAdd ? null : (
            <Grid item xs={12} md={12} display="flex" justifyContent="center">
              <img
                src={_EPayments.logo}
                alt=""
                style={{
                  height: "300px",
                  objectFit: "contain",
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Controller
              name="isPersonal"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={
                    watch("isPersonal")
                      ? "Send to my self"
                      : "Send to other person "
                  }
                  aria-label="active_deactivate"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isPersonal", e.target.checked);
                        }}
                      />
                    }
                    label="Is Personal !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="isInternational"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={
                    watch("isInternational")
                      ? "Can see this payment method if has international phone number or Iraqi phone number"
                      : "Can see this payment method only if has Iraqi phone number"
                  }
                  aria-label="active_deactivate"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isInternational", e.target.checked);
                        }}
                      />
                    }
                    label="Is International !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={watch("isActive") ? "Active" : "Inactive "}
                  aria-label="active_deactivate"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isActive", e.target.checked);
                        }}
                      />
                    }
                    label="Is Active !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={5}>
            {customList("All Provinces", left)}
          </Grid>
          <Grid
            item
            xs={2}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <AiOutlineArrowRight />
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              {left.length <= 0 ? (
                <DeleteForeverIcon />
              ) : (
                <AiOutlineArrowLeft />
              )}
            </Button>
          </Grid>
          <Grid item xs={5}>
            {selectedCustomList("Selected Provinces", right)}
          </Grid>
          <Grid item xs={12} md={12}>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {isAdd ? "ADD" : "UPDATE"}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default EPaymentsForm;

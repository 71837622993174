import React, { useState, useEffect } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Badge,
  LinearProgress,
  Stack,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";
import { useNavigate } from "react-router";
import { getAllAddresses } from "src/actions/addresses";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CustomSpinner from "src/components/CustomSpinner";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import LoginHistory from "../../LoginHistory";
import { CustomDialog } from "src/components/CustomDialog";
import UsersFilter from "./searchFilter";
import { BsTelegram } from "react-icons/bs";
import { FaBell, FaUserCheck, FaUserSlash } from "react-icons/fa";
import UserStatus from "../../userStatus";
import InfoIcon from "@mui/icons-material/Info";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import { IoMdCopy, IoMdPhonePortrait } from "react-icons/io";
import {
  getAllUsers,
  getById,
  getFilteredOnly,
  getUserPhoneCodeHistory,
  loginHistory,
  openDeviceInfoDialog,
  openNotifyUserDialog,
  openUsersDialog,
  openUsersStatusDialog,
  saveUsersPageNum,
  saveUsersSearchBody,
  userPhoneCodeHistoryDialog,
} from "src/actions/users";
import { openDialog } from "src/actions/promos";
import NotifyUser from "src/pages/cross/Users/Form/notification";
import ReactCountryFlag from "react-country-flag";
import { country_codes } from "src/utils/common";
import CustomerPromosForm from "src/pages/cross/promos/customer/from/Form";
import useCanShow from "src/components/CanShow";
import { MdEdit } from "react-icons/md";
import theme from "src/theme";
import UserPhoneCodeHistory from "src/pages/cross/Users/Form/phoneCodeHistory";
import UserTierMenu from "src/pages/cross/Users/userTierMenu";
import DeviceInfoDialog from "src/pages/cross/Users/deviceInfo";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function UsersTable() {
  const dispatch = useDispatch();

  const updateRole = useCanShow("31-06");
  const statusRole = useCanShow("31-04");
  const loginHistoryRole = useCanShow("31-11");
  const globalCartRole = useCanShow("06-09");
  const verticalCartRole = useCanShow("42-01");
  const walletCartRole = useCanShow("34-12");
  const promoRole = useCanShow("25-01");
  const notificationRole = useCanShow("19-02");
  const phoneCodeHistoryRole = useCanShow("31-20");

  let navigate = useNavigate();

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const saveUsersPageNO = useSelector((state) => state.users.saveUsersPageNum);
  const _Promos = useSelector((state) => state.promos.openDialog);
  const notifyUserDialog = useSelector((state) => state.users.notifyUserDialog);
  const loading = useSelector((state) => state.users.loading);
  const deviceInfoDialog = useSelector((state) => state.users.deviceInfo);

  const [page, setPage] = useState(saveUsersPageNO ? saveUsersPageNO : 0);
  const [openLoginHistoryDialog, setOpenLoginHistoryDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const _searchBody = useSelector((state) => state.users.searchUsersBody);

  const [loginHistoryData, setLoginHistoryData] = useState([]);
  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const users = useSelector((state) => state.users);

  useEffect(() => {
    if (_UserInfo?.roleOEndpoint?.filter((api) => api.Code === "31-10")[0]) {
      setReload(true);
      dispatch(
        getAllUsers(
          page + 1,
          _searchBody.pageSize,
          _searchBody ? _searchBody : {}
        )
      ).then((res) => {
        setReload(false);
      });
    } else if (
      _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "31-19")[0]
    ) {
      setReload(true);
      dispatch(
        getFilteredOnly(
          page + 1,
          _searchBody.pageSize,
          _searchBody ? _searchBody : {}
        )
      ).then((res) => {
        setReload(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_searchBody, page, refresh]);

  useEffect(() => {
    dispatch(saveUsersSearchBody(_searchBody));
  }, [_searchBody, dispatch]);

  useEffect(() => {
    dispatch(saveUsersPageNum(page));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(saveUsersSearchBody({ ..._searchBody, pageSize: newPageSize }));
    setPage(0);
  };

  // function changeUserState(id, status) {
  //   dispatch(changeStatus(id, status)).then((res) => {
  //     setRefresh(!refresh);
  //   });
  // }

  function getUserById(id) {
    setReload(true);
    dispatch(getById(id)).then((res) => {
      dispatch(openUsersDialog(users.openDialog));
      setReload(false);
    });
  }

  function getLoginHistory(id) {
    dispatch(loginHistory(id)).then((res) => {
      setLoginHistoryData(res.data);
    });
  }

  var columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack justifyContent="center" spacing={1}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography color="textPrimary" variant="body">
              {params.row.firstName
                ? params.row.firstName +
                  " " +
                  params.row.secondName +
                  " " +
                  params.row.lastName
                : "REGISTRATION NOT COMPLETED"}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <UserTierMenu
              userId={params.row.id}
              tier={params.row.customerTier}
              onSuccess={() => setRefresh(!refresh)}
            />
          </Box>
        </Stack>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <ReactCountryFlag
            style={{
              width: "40px",
              height: "30px",
              border: "solid 1px #dedede",
            }}
            svg
            countryCode={
              country_codes.filter(
                (code) => code.dial_code === params.row.countryCode
              )[0]?.code
            }
          />
          <Typography variant="body">{params.row.phoneNumber}</Typography>
        </Stack>
      ),
    },
    // {
    //   field: "currentCountry",
    //   headerName: "Current Country",
    //   flex: 1,
    //   headerAlign: "center",
    // },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "ordersCount",
      headerName: "NO. Orders",
      flex: 0.7,
      headerAlign: "center",
      renderCell: (params) =>
        _UserInfo.serviceType === "Vertical"
          ? params.row.giftOrdersCount ?? 0
          : params.row.ordersCount ?? 0,
    },
    {
      field: "balance",
      headerName: "Balance ($)",
      flex: 0.7,
      headerAlign: "center",
    },
    {
      field: "customerLoyaltyTierName",
      headerName: "Loyalty Tier",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography
          sx={{
            color:
              params.row.customerLoyaltyTier === 0
                ? "gray" // Silver
                : params.row.customerLoyaltyTier === 1
                ? "gold" // Gold
                : params.row.customerLoyaltyTier === 2
                ? "blue" // Diamond
                : "default",
          }}
        >
          {params.row.customerLoyaltyTierName}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("yyyy-MM-DD / hh:mm a")}
        </Typography>
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 0.7,
      headerAlign: "center",
      renderCell: (params) =>
        params.row.isActive ? (
          <Typography color="green">Active</Typography>
        ) : (
          <Stack
            direction="row"
            spacing={0}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography color="error">Inactive</Typography>
            {params.row.userDeactivationReasonEN ? (
              <IconButton>
                <Tooltip
                  title={params.row.userDeactivationReasonEN}
                  aria-label="cancel"
                >
                  <InfoIcon color="error" />
                </Tooltip>
              </IconButton>
            ) : null}
          </Stack>
        ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MdEdit size={25} color={theme.palette.primary.main} />}
          label="Edit"
          hidden={updateRole ? false : true}
          showInMenu
          onClick={() => {
            getUserById(params.row.id);
          }}
        />,
        <GridActionsCellItem
          icon={
            params.row.isActive ? (
              <FaUserCheck size={25} />
            ) : (
              <FaUserSlash size={25} />
            )
          }
          label="Status"
          hidden={statusRole ? false : true}
          showInMenu
          onClick={(e) => {
            setReload(true);
            dispatch(getById(params.row.id)).then((x) => {
              dispatch(openUsersStatusDialog());
              setReload(false);
            });
          }}
        />,
        <GridActionsCellItem
          icon={<IoMdCopy size={25} />}
          label="Copy User Data"
          hidden={_UserInfo.roles.includes("SystemAdmin") ? false : true}
          showInMenu
          onClick={(e) => {
            navigator.clipboard.writeText(
              `id: ${params.row.id}\nname: ${params.row.firstName} ${params.row.secondName} ${params.row.lastName}\nemail: ${params.row.email}\nphoneNumber: ${params.row.phoneNumber}\nisActive: ${params.row.isActive}\naddress: ${params.row.address}`
            );
            dispatch(showSuccessSnackbar("Text copied to clipboard"));
          }}
        />,
        <GridActionsCellItem
          icon={<Icon>history</Icon>}
          label="Login History"
          hidden={loginHistoryRole ? false : true}
          showInMenu
          onClick={(e) => {
            getLoginHistory(params.row.id);
            setOpenLoginHistoryDialog(true);
          }}
        />,
        <GridActionsCellItem
          icon={<Icon>phone</Icon>}
          label="Device Info"
          hidden={
            !params.row.deviceInfo || _UserInfo.roles.includes("SystemAdmin")
              ? false
              : true
          }
          showInMenu
          onClick={(e) => {
            dispatch(openDeviceInfoDialog(params.row.deviceInfo));
          }}
        />,
        <GridActionsCellItem
          icon={
            <StyledBadge
              badgeContent={
                _UserInfo.serviceType === "Vertical"
                  ? params.row.giftItemCount
                  : params.row.itemCount
              }
              color="secondary"
            >
              <Icon>shopping_cart</Icon>
            </StyledBadge>
          }
          label="Cart"
          hidden={verticalCartRole || globalCartRole ? false : true}
          showInMenu
          onClick={(e) => {
            if (_UserInfo.serviceType === "Vertical") {
              navigate(`/app/gift_cart/${params.row.giftCartId}`);
            } else {
              navigate(`/app/cart/${params.row.cartId}`);
            }
          }}
        />,
        <GridActionsCellItem
          icon={<BsTelegram size={25} color="#229ED9" />}
          label="Chat Via Telegram"
          showInMenu
          onClick={(e) => {
            window.open(`https://t.me/+964${params.row.phoneNumber}`, "_blank");
          }}
        />,
        <GridActionsCellItem
          icon={<WhatsAppIcon color="success" />}
          label="Chat Via Whatsapp"
          showInMenu
          onClick={(e) => {
            window.open(
              `https://wa.me/+964${params.row.phoneNumber}`,
              "_blank"
            );
          }}
        />,
        <GridActionsCellItem
          icon={<Icon>wallet</Icon>}
          label="Wallet"
          hidden={walletCartRole ? false : true}
          showInMenu
          onClick={(e) => {
            navigate(`/app/wallet/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Icon>fact_check</Icon>}
          label="Orders"
          hidden={_UserInfo.serviceType === "Vertical" ? true : false}
          showInMenu
          onClick={(e) => {
            navigate(`/app/user_orders/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Icon>confirmation_number</Icon>}
          label="Create Promo"
          hidden={promoRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(openDialog());
            setSelectedUser(params.row);
          }}
        />,
        <GridActionsCellItem
          icon={<FaBell size={25} />}
          label="Send notification"
          hidden={notificationRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(openNotifyUserDialog());
            setSelectedUser(params.row);
          }}
        />,
        <GridActionsCellItem
          icon={<IoMdPhonePortrait size={25} />}
          label="Phone Code History"
          hidden={phoneCodeHistoryRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(getUserPhoneCodeHistory(params.row.phoneNumber)).then(() =>
              dispatch(userPhoneCodeHistoryDialog())
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      {deviceInfoDialog ? <DeviceInfoDialog /> : null}
      <CustomDialog
        isOpen={openLoginHistoryDialog}
        handleClose={() => setOpenLoginHistoryDialog(false)}
        title="Login history"
        maxWidth="md"
      >
        <div>
          <LoginHistory data={loginHistoryData} />
        </div>
      </CustomDialog>
      {users.openStatusDialog && <UserStatus loading={reload} />}
      {_Promos ? (
        <CustomerPromosForm
          userId={selectedUser.id}
          name={`CR${Math.random().toString(36).substring(2, 6).toUpperCase()}`}
        />
      ) : null}
      {notifyUserDialog ? (
        <NotifyUser
          userId={selectedUser.id}
          userName={`${selectedUser.firstName} ${selectedUser.secondName} ${selectedUser.lastName}`}
        />
      ) : null}
      <UserPhoneCodeHistory />
      <UsersFilter pageNo={(newPage) => setPage(newPage)} />
      <PerfectScrollbar>
        <Box sx={{ margin: 2 }}>
          {!users ? (
            <CustomSpinner />
          ) : (
            <Box>
              <Box sx={{ height: { md: "calc(100vh - 330px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      paddingLeft: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      paddingLeft: "15px",
                    },
                    ".MuiDataGrid-cell": {
                      justifyContent: "center",
                    },
                  }}
                  loading={reload || loading}
                  rows={users.data}
                  columns={columns}
                  rowHeight={80}
                  disableSelectionOnClick
                  pagination
                  pageSize={_searchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowCount={users.rowCount}
                  paginationMode="server"
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
